const App = () => {
  return (
    <div>
      <div className="context">
        <h1>Ahmet Hakan Aydın</h1>
        <h5>Full Stack Developer</h5>
        <ul className="link-list">
          <li>
            <a href="https://github.com/ahmethakanaydin" target="_blank" rel="noreferrer">github</a>
          </li>
          <li>
            <a href="https://tr.linkedin.com/in/ahmethakanaydin" target="_blank" rel="noreferrer">linkedIn</a>
          </li>
          <li>
            <a href="mailto:ahmet@hakanaydin.me" target="_blank" rel="noreferrer">mail</a>
          </li>
        </ul>
      </div>

      <div className="area" >
        <ul className="circles">
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
        </ul>
      </div >
    </div>
  );
}

export default App;
